import React, { useEffect } from "react";

const APP_STORE_URL = "https://apps.apple.com/app/cur8-by-ifg/id1665655843";
const PLAY_STORE_URL =
  "https://play.google.com/store/apps/details?id=capital.cur8";

const DownloadApp: React.FC = () => {
  useEffect(() => {
    // Check if user is on iOS
    const isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent);

    // Redirect to appropriate store
    window.location.href = isIOS ? APP_STORE_URL : PLAY_STORE_URL;
  }, []);

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
        textAlign: "center",
        padding: "20px",
      }}
    >
      <p>Redirecting you to download the Cur8 app...</p>
    </div>
  );
};

export default DownloadApp;
